import React from "react";
import { Container, Grid } from "@nodeme/grid-react";
import { buildClasses, IDefinitions, ITheme } from "@nodeme/jss-react";
import img from "../assets/dog.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGift } from "@fortawesome/pro-light-svg-icons";
import { TCreateContext } from "../Translations";

const styles = (theme: ITheme) => (definitions: IDefinitions) => ({
  img: {
    width: "100%",
    [theme.breakpoints.down("lg")]: {
      display: "none",
    },
  },
  icon: {
    marginLeft: "8px",
  },
  text: {
    padding: "100px 50px 0px 0px",
    [theme.breakpoints.down("lg")]: {
      padding: "0px",
    },
  },
});

interface IProps {
  createContext: TCreateContext;
}

function Gifts(props: IProps) {
  const classes = buildClasses(styles);
  const getTR = props.createContext("gifts");
  return (
    <Container notFluid>
      <Grid spacing={16} root={{ left: 0, top: 0, right: 0, bottom: 0 }}>
        <Grid item lg="rest-fixed">
          <h1 className={classes.headline}>
            {getTR("headline")}
            <FontAwesomeIcon className={classes.icon} icon={faGift} />
          </h1>
          <div className={classes.text}>
            <p>{getTR("body")}</p>
          </div>
        </Grid>
        <Grid item lg="400px">
          <img className={classes.img} alt="mona-jo-in-paris" src={img} />
        </Grid>
      </Grid>
    </Container>
  );
}

export default Gifts;
