import React, { useEffect, useState } from "react";
import { Container, Grid } from "@nodeme/grid-react";
import { buildClasses, IDefinitions, ITheme } from "@nodeme/jss-react";
import classnames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRingsWedding } from "@fortawesome/pro-light-svg-icons";
import { TCreateContext } from "../Translations";

const styles = (theme: ITheme) => (definitions: IDefinitions) => ({
  label: {
    color: "#000",
    textAlign: "right",
    padding: "16px",
    fontSize: "1.2rem",
    border: "1px solid " + theme.palette.get("lightGrey"),
    [theme.breakpoints.down("lg")]: {
      textAlign: "left",
    },
  },
  counter: {
    textAlign: "center",
    padding: "16px",
    color: "#000",
    fontSize: "1.2rem",
    border: "1px solid " + theme.palette.get("lightGrey"),
    borderLeft: "0px",
    [theme.breakpoints.down("lg")]: {
      border: "1px solid " + theme.palette.get("lightGrey"),
      borderTop: "0px",
    },
  },
  big: {
    fontWeight: 600,
    fontSize: "1.8rem",
  },
});

interface IState {
  days: number;
  houres: number;
  minutes: number;
  seconds: number;
}

const end = +new Date("Sat May 07 2022 15:00:00 GMT+0200");
const getCurrentState = () => {
  let left = Math.floor((end - +new Date()) / 1000);

  const days = Math.floor(left / 86400);
  left = left % 86400;
  const houres = Math.floor(left / 3600);
  left = left % 3600;
  const minutes = Math.floor(left / 60);
  const seconds = Math.floor(left % 60);
  return {
    days,
    houres,
    minutes,
    seconds,
  };
};

const creatCron = (
  state: IState,
  setState: (state: IState) => void,
  mounted: boolean
) => {
  return () => {
    if (mounted) {
      setState({ ...state, ...getCurrentState() });

      setTimeout(() => creatCron(state, setState, mounted)(), 1000);
    }
  };
};

interface IProps {
  createContext: TCreateContext;
}

function Countdown(props: IProps) {
  const classes = buildClasses(styles);
  const [state, setState] = useState<IState>(getCurrentState());
  const getTR = props.createContext("countdown");

  useEffect(() => {
    let mounted = true;
    setTimeout(() => {
      creatCron(state, setState, mounted)();
    }, 1000);
    return () => {
      mounted = false;
    };
  });

  return (
    <section id="countdown">
      <Container notFluid spacing={0}>
        <Grid root={{ top: 16 }}>
          <Grid item lg={3}>
            <div className={classes.label}>
              <Grid>
                <Grid item xs="auto" sm="auto" md="auto" lg="rest-fixed">
                  <FontAwesomeIcon
                    style={{ fontSize: "3rem", margin: "12px 16px 0px 0px" }}
                    icon={faRingsWedding}
                  />
                </Grid>
                <Grid
                  item
                  xs="rest-fixed"
                  sm="rest-fixed"
                  md="rest-fixed"
                  lg="auto"
                >
                  {getTR("missing")}
                  <br />
                  <span className={classes.big}>{getTR("wedding")}</span>
                </Grid>
              </Grid>
            </div>
          </Grid>
          <Grid item xs={5} lg={7 / 4}>
            <div className={classnames([classes.counter, classes.days])}>
              <span className={classes.big}>{state.days}</span>
              <br />
              {getTR("days")}
            </div>
          </Grid>
          <Grid item xs={5} lg={7 / 4}>
            <div className={classnames([classes.counter, classes.houres])}>
              <span className={classes.big}>{state.houres}</span>
              <br />
              {getTR("hours")}
            </div>
          </Grid>
          <Grid item xs={5} lg={7 / 4}>
            <div className={classnames([classes.counter, classes.minutes])}>
              <span className={classes.big}>{state.minutes}</span>
              <br />
              {getTR("minutes")}
            </div>
          </Grid>
          <Grid item xs={5} lg={7 / 4}>
            <div className={classnames([classes.counter, classes.seconds])}>
              <span className={classes.big}>{state.seconds}</span>
              <br />
              {getTR("seconds")}
            </div>
          </Grid>
        </Grid>
      </Container>
    </section>
  );
}

export default Countdown;
