import React from "react";
import { Container, Grid } from "@nodeme/grid-react";
import { buildClasses, IDefinitions, ITheme } from "@nodeme/jss-react";
import img from "../assets/photo.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCamera } from "@fortawesome/pro-light-svg-icons";
import { TCreateContext } from "../Translations";
import { Form } from "@nodeme/forms-react";
import Button from "@nodeme/forms-react/lib/Components/Button";

const styles = (theme: ITheme) => (definitions: IDefinitions) => ({
  icon: {
    marginRight: "8px",
    [theme.breakpoints.down("lg")]: {
      display: "none",
    },
  },
  img: {
    width: "100%",
    [theme.breakpoints.down("lg")]: {
      display: "none",
    },
  },
  text: {
    padding: "120px 0px 0px 100px",
    [theme.breakpoints.down("lg")]: {
      padding: "0px",
    },
  },
  headline: {
    textAlign: "right",
    [theme.breakpoints.down("lg")]: {
      textAlign: "left",
    },
  },
  iconResp: {
    marginLeft: "8px",
    [theme.breakpoints.up("lg")]: {
      display: "none",
    },
  },
});

interface IProps {
  createContext: TCreateContext;
}

function Meals(props: IProps) {
  const classes = buildClasses(styles);
  const getTR = props.createContext("upload");
  return (
    <Container notFluid>
      <Grid spacing={50} root={{ left: 0, top: 0, right: 0, bottom: 0 }}>
        <Grid item lg="400px">
          <img className={classes.img} alt="mona-jo-in-paris" src={img} />
        </Grid>
        <Grid item lg="rest-fixed">
          <h1 className={classes.headline}>
            <FontAwesomeIcon className={classes.icon} icon={faCamera} />
            {getTR("headline")}
            <FontAwesomeIcon className={classes.iconResp} icon={faCamera} />
          </h1>
          <div className={classes.text}>
            <h4>{getTR("teaser")}</h4>
            <Form onSubmit={() => {}}>
              <Grid alignItems="center">
                <Button
                  xs="150px"
                  primary
                  onClick={(e) => {
                    e.preventDefault();
                    window.location.href =
                      "https://www.dropbox.com/request/wemXlIHjUHJxpceePyiw";
                  }}
                >
                  Zum Upload
                </Button>
              </Grid>
            </Form>
          </div>
        </Grid>
      </Grid>
    </Container>
  );
}

export default Meals;
