import React from "react";
import { Container, Grid } from "@nodeme/grid-react";
import { buildClasses, IDefinitions, ITheme } from "@nodeme/jss-react";
import img from "../assets/Covid.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faVirus } from "@fortawesome/pro-light-svg-icons";
import { TCreateContext } from "../Translations";

const styles = (theme: ITheme) => (definitions: IDefinitions) => ({
  img: {
    width: "100%",
    [theme.breakpoints.down("lg")]: {
      display: "none",
    },
  },
  icon: {
    marginRight: "8px",
    [theme.breakpoints.down("lg")]: {
      display: "none",
    },
  },
  iconResp: {
    marginLeft: "8px",
    [theme.breakpoints.up("lg")]: {
      display: "none",
    },
  },
  text: {
    padding: "120px 0px 0px 100px",
    [theme.breakpoints.down("lg")]: {
      padding: "0px",
    },
  },
  headline: {
    textAlign: "right",
    [theme.breakpoints.down("lg")]: {
      textAlign: "left",
    },
  },
});

interface IProps {
  createContext: TCreateContext;
}

function Virus(props: IProps) {
  const classes = buildClasses(styles);
  const getTR = props.createContext("virus");
  return (
    <Container notFluid>
      <Grid spacing={16} root={{ left: 0, top: 0, right: 0, bottom: 0 }}>
        <Grid item lg="400px">
          <a
            rel="noreferrer"
            target="_blank"
            href="https://www.pexels.com/de-de/@artjazz?utm_content=attributionCopyText&utm_medium=referral&utm_source=pexels"
          >
            <img className={classes.img} alt="mona-lisa-with-mask" src={img} />
          </a>
        </Grid>
        <Grid item lg="rest-fixed">
          <h1 className={classes.headline}>
            <FontAwesomeIcon className={classes.icon} icon={faVirus} />
            {getTR("headline")}
            <FontAwesomeIcon className={classes.iconResp} icon={faVirus} />
          </h1>
          <div className={classes.text}>
            <h4>{getTR("teaser")}</h4>
            <p>{getTR("body")}</p>
          </div>
        </Grid>
      </Grid>
    </Container>
  );
}

export default Virus;
