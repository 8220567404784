import React from "react";
import { Container, Grid } from "@nodeme/grid-react";
import { buildClasses, IDefinitions, ITheme } from "@nodeme/jss-react";
import img from "../assets/mojo.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock } from "@fortawesome/pro-light-svg-icons";
import { TCreateContext } from "../Translations";

const styles = (theme: ITheme) => (definitions: IDefinitions) => ({
  img: {
    marginTop: "32px",
    width: "100%",
    [theme.breakpoints.down("lg")]: {
      display: "none",
    },
  },
  icon: {
    marginRight: "8px",
    [theme.breakpoints.down("lg")]: {
      display: "none",
    },
  },
  iconResp: {
    marginLeft: "8px",
    [theme.breakpoints.up("lg")]: {
      display: "none",
    },
  },
  text: {},
  headline: {
    textAlign: "right",
    [theme.breakpoints.down("lg")]: {
      textAlign: "left",
    },
  },
  map: {
    border: "0px",
  },
});

interface IProps {
  createContext: TCreateContext;
}

function Schedual(props: IProps) {
  const classes = buildClasses(styles);
  const getTR = props.createContext("schedule");

  return (
    <Container notFluid>
      <Grid spacing={50} root={{ left: 0, top: 0, right: 0, bottom: 0 }}>
        <Grid item lg={5}>
          <img className={classes.img} alt="mona-jo-black-white" src={img} />
        </Grid>
        <Grid item lg={5}>
          <h1 className={classes.headline}>
            <FontAwesomeIcon className={classes.icon} icon={faClock} />
            {getTR("headline")}
            <FontAwesomeIcon className={classes.iconResp} icon={faClock} />
          </h1>
          <div className={classes.text}>
            <h4>{getTR("teaser")}</h4>
            <p>{getTR("body")}</p>
            <ul>
              <li>{getTR("li1")}</li>
              <li>{getTR("li2")}</li>
              <li>{getTR("li3")}</li>
              <li>{getTR("li4")}</li>
            </ul>
          </div>
        </Grid>
        <Grid item lg={5}>
          <h4>{getTR("headline2")}</h4>
          <p>{getTR("body2")}</p>
        </Grid>
        <Grid item lg={5}>
          <h4>{getTR("headline3")}</h4>
          <p>{getTR("body3")}</p>
        </Grid>
        <Grid item lg={10}>
          <iframe
            className={classes.map}
            title="map"
            width="100%"
            height="450"
            loading="lazy"
            src="https://www.google.com/maps/embed/v1/place?key=AIzaSyASXZnyqKiNA3TAOIDFguf8hhaNEOrOSwQ&q=Kleinsasserhof, 9800 Spittal an der Drau"
          ></iframe>
        </Grid>
      </Grid>
    </Container>
  );
}

export default Schedual;
