import { Container, Grid } from "@nodeme/grid-react";
import { buildClasses, IDefinitions, ITheme } from "@nodeme/jss-react";
import React from "react";
import img from "../assets/main.jpeg";

const styles = (theme: ITheme) => (definitions: IDefinitions) => ({
  img: {
    width: "100%",
  },
  headline: {
    textAlign: "center",
    margin: "16px 0px",
    fontFamily: '"Freehand", cursive;',
    fontSize: "3rem",
  },
});

function Landingpage() {
  const classes = buildClasses(styles);
  return (
    <section id="landingpage">
      <header>
        <Container notFluid>
          <Grid justify="center" root={{ top: 32, bottom: 32 }}>
            <Grid item lg="700px">
              <img className={classes.img} alt="mona-jo-scatch" src={img} />
            </Grid>
          </Grid>
        </Container>
      </header>
    </section>
  );
}

export default Landingpage;
