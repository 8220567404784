import React from "react";
import { Container, Grid } from "@nodeme/grid-react";
import { buildClasses, IDefinitions, ITheme } from "@nodeme/jss-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShuttleVan } from "@fortawesome/pro-light-svg-icons";
import img1 from "../assets/acco/pic1.jpg";
import img2 from "../assets/acco/pic2.jpg";
import img5 from "../assets/acco/pic5.jpg";
import img9 from "../assets/acco/pic9.jpg";
import { TCreateContext } from "../Translations";

const styles = (theme: ITheme) => (definitions: IDefinitions) => ({
  img: {
    display: "block",
    width: "100%",
  },
  images: {
    [theme.breakpoints.down("lg")]: {
      display: "none",
    },
  },
  icon: { marginLeft: "8px" },
  link: { color: theme.palette.get("success") },
  hotellist: {
    "& > li": {
      margin: "12px 0px",
    },
  },
});

interface IProps {
  createContext: TCreateContext;
}

function TransportAccommodation(props: IProps) {
  const classes = buildClasses(styles);
  const getTR = props.createContext("transportAcco");
  return (
    <Container notFluid>
      <Grid spacing={16} root={{ left: 0, top: 0, right: 0, bottom: 0 }}>
        <Grid item>
          <div>
            <h1>
              {getTR("headline")}
              <FontAwesomeIcon className={classes.icon} icon={faShuttleVan} />
            </h1>
          </div>
        </Grid>
        <Grid item lg={5}>
          <h4>{getTR("teaser1")}</h4>
          <p>
            {getTR("prebody1")}
            <br />
            {getTR("body1")}
          </p>
          <p>{getTR("appbody1")}</p>
          <ul className={classes.hotellist}>
            <li>
              <a className={classes.link} href="http://simeter.at/">
                <b>{getTR("li1")}</b>
              </a>
              <ul>
                <li>{getTR("sli1")}</li>
              </ul>
            </li>
            <li>
              <a className={classes.link} href="https://erlebnis-post.at/">
                <b>{getTR("li2")}</b>
              </a>
              <ul>
                <li>{getTR("sli2")}</li>
              </ul>
            </li>
            <li>
              <a className={classes.link} href="http://familiengasthof.at/">
                <b>{getTR("li3")}</b>
              </a>
              <ul>
                <li>{getTR("sli3")}</li>
              </ul>
            </li>
            <li>
              <a className={classes.link} href="https://www.luginsland.at/">
                <b>{getTR("li4")}</b>
              </a>
              <ul>
                <li>{getTR("sli4")}</li>
              </ul>
            </li>
          </ul>
        </Grid>
        <Grid item lg={5}>
          <h4>{getTR("teaser2")}</h4>
          <p>{getTR("body2")}</p>
        </Grid>
        <Grid item>
          <div className={classes.images}>
            <Grid
              spacing={16}
              vertical
              root={{ top: 32, right: 0, left: 0, bottom: 0 }}
            >
              <Grid item lg={10 / 4}>
                <img className={classes.img} alt="Hotel 1" src={img1} />
              </Grid>
              <Grid item lg={10 / 4}>
                <img className={classes.img} alt="Hotel 2" src={img2} />
              </Grid>
              <Grid item lg={10 / 4}>
                <img className={classes.img} alt="Hotel 4" src={img5} />
              </Grid>
              <Grid item lg={10 / 4}>
                <img className={classes.img} alt="Hotel 5" src={img9} />
              </Grid>
            </Grid>
          </div>
        </Grid>
      </Grid>
    </Container>
  );
}

export default TransportAccommodation;
