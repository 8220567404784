import React from "react";
import { Container } from "@nodeme/grid-react";
import { buildClasses, IDefinitions, ITheme } from "@nodeme/jss-react";

const styles = (theme: ITheme) => (definitions: IDefinitions) => ({
  hr: {
    height: "1px",
    width: "100%",
    backgroundColor: theme.palette.get("lightGrey"),
    margin: "100px 0px",
    border: "0px",
  },
});

interface IProps {
  id?: string;
}

function Countdown(props: IProps) {
  const classes = buildClasses(styles);
  return (
    <Container notFluid>
      <hr className={classes.hr} id={props.id} />
    </Container>
  );
}

export default Countdown;
