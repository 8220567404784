import React from "react";
import "./App.css";
import Contacts from "./components/Contacts";
import Countdown from "./components/Countdown";
import Covid from "./components/Covid";
import Gifts from "./components/Gifts";
import Landingpage from "./components/Landingpage";
import Menu from "./components/Menu";
import RSVP from "./components/RSVP";
import Schedual from "./components/Schedual";
import Seperator from "./components/Seperator";
import TransportAccommodation from "./components/TransportAccommodation";
import useTranslations from "./Translations";
import Meals from "./components/Meals";
import Upload from "./components/Upload";

function App() {
  const [createContext, lang, setLang] = useTranslations();

  return (
    <div className="App">
      <main>
        <Menu createContext={createContext} lang={lang} setLang={setLang} />
        <Landingpage />
        <Countdown createContext={createContext} />
        <Seperator id="schedule" />
        <Schedual createContext={createContext} />
        <Seperator id="transpoort" />
        <TransportAccommodation createContext={createContext} />
        <Seperator id="gifts" />
        <Gifts createContext={createContext} />
        <Seperator id="covid" />
        <Covid createContext={createContext} />
        <Seperator id="meals" />
        <Meals createContext={createContext} />
        <Seperator id="upload" />
        <Upload createContext={createContext} />
        <Seperator id="contacts" />
        <Contacts createContext={createContext} />
        <Seperator />
      </main>
    </div>
  );
}

export default App;
