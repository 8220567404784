import React from "react";
import { Container, Grid } from "@nodeme/grid-react";
import { buildClasses, IDefinitions, ITheme } from "@nodeme/jss-react";
import img from "../assets/meals.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTurkey } from "@fortawesome/pro-light-svg-icons";
import { TCreateContext } from "../Translations";

const styles = (theme: ITheme) => (definitions: IDefinitions) => ({
  headline: {
    textAlign: "left",
    [theme.breakpoints.down("lg")]: {
      textAlign: "left",
    },
  },
  subheadline: {
    marginBottom: 8,
  },
  text: {
    marginTop: 0,
  },
  img: {
    width: "100%",
    [theme.breakpoints.down("lg")]: {
      display: "none",
    },
  },
  icon: {
    marginLeft: "8px",
  },
});

interface IProps {
  createContext: TCreateContext;
}

function Meals(props: IProps) {
  const classes = buildClasses(styles);
  const getTR = props.createContext("meals");
  return (
    <Container notFluid>
      <Grid spacing={50} root={{ left: 0, top: 0, right: 0, bottom: 0 }}>
        <Grid item lg="rest-fixed">
          <h1 className={classes.headline}>
            {getTR("headline")}
            <FontAwesomeIcon className={classes.icon} icon={faTurkey} />
          </h1>
          <p>{getTR("body")}</p>
          <Grid spacing={50} root={{ left: 0, top: 0, right: 0, bottom: 0 }}>
            <Grid item>
              <h4 className={classes.subheadline}>{getTR("teaser1")}</h4>
              <p className={classes.text}>
                {getTR("v1")}
                <br />
                {getTR("s1")}
                <br />
                {getTR("h1")}
                <br />
                {getTR("label4")}
              </p>
            </Grid>
            <Grid item>
              <h4 className={classes.subheadline}>{getTR("teaser2")}</h4>
              <p className={classes.text}>
                {getTR("v1")}
                <br />
                {getTR("s1")}
                <br />
                {getTR("h2")}
                <br />
                {getTR("label4")}
              </p>
            </Grid>
            <Grid item>
              <h4 className={classes.subheadline}>{getTR("teaser3")}</h4>
              <p className={classes.text}>
                {getTR("v2")}
                <br />
                {getTR("s2")}
                <br />
                {getTR("h3")}
                <br />
                {getTR("label4")}
              </p>
            </Grid>
          </Grid>
        </Grid>
        <Grid item lg="400px">
          <img className={classes.img} alt="mona-jo-black-white" src={img} />
        </Grid>
      </Grid>
    </Container>
  );
}

export default Meals;
