import { useState } from "react";

export const translations: {
  [key: string]: { [key: string]: [string, string] };
} = {
  menu: {
    schedule: ["Where, When, How?", "Wo, Wann, Wie?"],
    transport: ["Transport & Accommodation", "Transport & Unterkunft"],
    gifts: ["Gifts", "Geschenke"],
    covid: ["COVID", "COVID"],
    rsvp: ["RSVP", "RSVP"],
    contact: ["Contact", "Kontakt"],
    meals: ["Meals", "Speisen"],
    upload: ["Uplaod", "Uplaod"],
  },
  countdown: {
    missing: ["Countdown to the", "Countdown bis zur"],
    wedding: ["Wedding", "Hochzeit"],
    days: ["Days", "Tage"],
    hours: ["Hours", "Stunden"],
    minutes: ["Minutes", "Minuten"],
    seconds: ["Seconds", "Sekunden"],
  },
  schedule: {
    headline: ["Where, When, How?", "Wo, Wann, Wie?"],
    teaser: ["Saturday 7th of May 2022", "Samstag 7. Mai 2022"],
    body: [
      "Both the wedding ceremony and the subsequent celebrations will take place at Kleinsasserhof which will be available exclusively to our wedding party that weekend.",
      "Sowohl die Trauung als auch die anschließende Feier finden am Kleinsasserhof statt, der an diesem Wochenende ausschließlich unserer Hochzeitsgesellschaft zur Verfügung stehen wird.",
    ],
    li1: [
      "2 p.m. reception with aperitif and sandwiches",
      "14:00 Uhr Empfang mit Aperitif und Brötchen",
    ],
    li2: ["3 p.m. wedding ceremony", "15:00 Uhr Trauung"],
    li3: [
      "4 p.m. agape with coffee and cake",
      "16:00 Uhr Agape mit Torte und Kaffee",
    ],
    li4: ["7 p.m. dinner", "19:00 Uhr Abendessen"],
    headline2: ["Dress Code", "Dresscode"],
    body2: [
      "Please wear whatever you like and feel comfortable in, but maybe try to choose an outfit that won't make you an eyesore in our wedding pictures. Hopefully the ceremony will be outside, therefore high heels might not be the best idea.",
      "Tragt bitte was immer euch gefällt und worin ihr euch wohl fühlt, aber versucht vielleicht ein Outfit zu wählen, das euch nicht zu einem Schandfleck auf unseren Hochzeitsfotos werden lässt. Bei schönem Wetter findet die Trauung auf der Wiese statt. Wählt euer Schuhwerk entsprechend.",
    ],
    headline3: ["Photographer", "Fotograf"],
    body3: [
      "We will have a very friendly photographer at our wedding who has promised to only take incredibly flattering pictures of us and our guests. If you do not want to be photographed, please let us know. In this case, you will be given a red sticky dot on your forehead, which you will have to wear all day.",
      "Bei unserer Hochzeit wird ein sehr sympathischer Fotograf zugegen sein, der uns versprochen hat ausschließlich wahnsinnig vorteilhafte Fotos von uns und den Gästen zu machen. Falls ihr nicht fotografiert werden möchtet, lasst es uns bitte wissen. In diesem Fall bekommt ihr einen roten Klebepunkt auf die Stirn geklebt, den ihr dann bitte den ganzen Tag tragen müsst.",
    ],
  },
  transportAcco: {
    headline: ["Transport & Accommodation", "Transport & Unterkunft"],
    teaser1: ["Accommodation", "Unterkünfte"],
    prebody1: ["Directly at Kleinsasserhof:", "Direkt am Kleinsasserhof:"],
    body1: [
      "There are a limited number of rooms at Kleinsasserhof that can be booked directly through us for Friday and Saturday. Please contact us if you would like to stay there. We will allocate the available rooms on a first-come, first-served basis. Rate: 140€ per person for 2 nights.",
      "Es gibt eine begrenzte Anzahl an Zimmern am Kleinsasserhof, die für Freitag und Samstag direkt über uns gebucht werden können. Bitte kontaktiert uns, falls ihr gerne am Freitag und Samstag am Kleinsasserhof übernachten 	möchtet. Wir teilen die verfügbaren Zimmer nach dem “First Come, First Serve”-Prinzip zu. Preis: 140€ pro Person für 2 Nächte.",
    ],
    appbody1: [
      "If you are not staying at Kleinsasserhof, please book your own accomodation. Here is a short list of suggestions:",
      "Falls ihr nicht am Kleinsasserhof übernachtet, bucht euch eure Unterkunft bitte selbst. Hier eine kurze Liste mit Vorschlägen für Unterkünfte in der Nähe:",
    ],
    li1: ["Hotel Landhof Simeter", "Hotel Landhof Simeter"],
    sli1: [
      "approx. 110€/130€ per person (2 nights)",
      "Preis: ca. 110€/130€ pro Person (2 Nächte)",
    ],
    li2: [
      "Erlebnis Post - Spittal an der Drau",
      "Erlebnis Post - Spittal an der Drau",
    ],
    sli2: [
      "approx. 110€/130€ per person (2 nights)",
      "Preis: ca. 120€/140€ pro Person (2 Nächte)",
    ],
    li3: ["Familiengasthof St. Wolfgang", "Familiengasthof St. Wolfgang"],
    sli3: [
      "approx. 100€ per person (2 nights)",
      "Preis: ca. 100€ pro Person (2 Nächte)",
    ],
    li4: ["luginsland", "luginsland"],
    sli4: [
      "approx. 80€ per person (2 nights)",
      "Preis: ca. 80€ pro Person (2 Nächte)",
    ],
    teaser2: ["Transport", "Transport"],
    body2: [
      "There is limited parking available at Kleinsasserhof. In order for you to party with us and not worry about getting home, we will organise a shuttle service to pick you up from your accommodation (should you not stay at Kleinsasserhof) and bring you back in the evening/at night.",
      "Am Kleinsasserhof gibt es eine begrenzte Anzahl an Parkplätzen. Damit ihr ausgelassen mit uns feiern könnt und euch nicht um eure Heimfahrt sorgen müsst, werden wir einen Shuttle-Service organisieren der euch sowohl von eurer Unterkunft (solltet ihr nicht am Kleinsasserhof übernachten) abholt, als auch dann am Abend bzw. in der Nacht wieder zurückbringt.",
    ],
  },
  meals: {
    headline: ["Meals", "Speisen"],
    body: [
      "Please pick one of the following menu options:",
      "Bitte gebt bei eurer Rückmeldung eure Menüauswahl bekannt. Ihr könnt aus folgenden Menüs wählen:",
    ],
    teaser1: ["Option 1 - Beef:", "Auswahl 1 - Rind:"],
    teaser2: ["Option 2 - Chicken:", "Auswahl 2 - Huhn:"],
    teaser3: [
      "Options 3/4 - Vegan/Vegetarian:",
      "Auswahl 3/4 - Vegan/Vegetarisch:",
    ],
    label1: ["Appetizer", "Vorspeise"],
    label2: ["Soup", "Suppe"],
    label3: ["Entree", "Hauptgang"],
    label4: ["Dessert", "Dessert"],
    v1: ["Kärtner Kasnudl", "Kärtner Kasnudl"],
    v2: ["Kartoffel-Nudel", "Kartoffel-Nudel"],
    s1: ["Rindsuppe mit Fritatten", "Rindsuppe mit Fritatten"],
    s2: ["Wildkräuersuppe", "Wildkräuersuppe"],
    h1: [
      "Rinderfilet im Ganzen gebraten mit Charlotten-Maderiasauce, Erdäpfel-Selleriepürree und Karotten",
      "Rinderfilet im Ganzen gebraten mit Charlotten-Maderiasauce, Erdäpfel-Selleriepürree und Karotten",
    ],
    h2: [
      "Geschmortes Bauernhendl in Rosmarinsaftl mit Gemüse und Jasminreis",
      "Geschmortes Bauernhendl in Rosmarinsaftl mit Gemüse und Jasminreis",
    ],
    h3: [
      "Polentaauflauf mit Gemüse und Tomatenragout (mit oder ohne Mozzarella überbacken)",
      "Polentaauflauf mit Gemüse und Tomatenragout (mit oder ohne Mozzarella überbacken)",
    ],
  },
  gifts: {
    headline: ["Gifts", "Geschenke"],
    body: [
      "We are in love and have everything we need, except enough money to finish our construction site. Hence, if you would like to give us a gift, don't give us any material items, but money!",
      "Wir sind verliebt und haben alles was wir brauchen, außer ausreichend Geld um unsere Baustelle fertig zu stellen. Falls ihr uns etwas schenken möchtet, schenkt uns bitte kein Klumpert, sondern Geld! ",
    ],
  },
  virus: {
    headline: ["Virus", "Virus"],
    teaser: ["Better safe than sorry!", "Better safe than sorry!"],
    body: [
      "In order for everyone to feel safe and comfortable at our wedding, we ask that all guests arrive adequately vaccinated and/or recovered. We also recommend highly that all guests take a PCR test shortly before the wedding if possible.",
      "Damit sich alle auf unserer Hochzeitsfeier sicher und wohl fühlen können, bitten wir euch ausreichend geimpft und/oder genesen zu erscheinen. Wir empfehlen auch allen Gästen inständig kurz vor der Hochzeit nach Möglichkeit noch einen PCR-Test zu machen.",
    ],
  },
  rsvp: {
    headline: ["Répondez S'il Vous Plaît", "Répondez S'il Vous Plaît"],
    body: [
      "Please let us know by 4th of March 2022 if you can come to our wedding and when you will arrive.",
      "Bitte lasst uns bis zum 4. März 2022 wissen ob ihr kommen könnt.",
    ],
    f1: ["Firstname", "Vorname"],
    f2: ["Lastname", "Nachname"],
    f3: ["Menu", "Menü"],
    f3o1: ["Beef", "Rind"],
    f3o2: ["Chicken", "Huhn"],
    f3o3: ["Vegetarian", "Vegetarisch"],
    f3o4: ["Vegan", "Vegan"],
    f4: ["E-mail", "E-Mail"],
    f5: ["Phone", "Telefon"],
    f6: ["Day of arrival", "Tag der Ankunft"],
    f6o1: ["Friday 6th of May 2022", "Freitag 6. Mai 2022"],
    f6o2: ["Saturday 7th of May 2022", "Samstag 7. Mai 2022"],
    f6o3: ["Unfortunately, I cannot attend", "Leider kann ich nicht kommen"],
    f7: ["Hotel", "Unterkunft"],
    f8: ["Allergies or intolerances?", "Allergien oder Unverträglichkeiten?"],
    button: ["Send", "Abschicken"],
    pending: ["pending...", "sende..."],
    successHeadline: ["Success!", "Erfolg!"],
    successBody: [
      "Thanks, your RSVP has been sent!",
      "Danke, deine/eure Antwort wurde versendet!",
    ],
    notSet: ["Mandatory field!", "Ist ein Pflichtfeld!"],
    notEmail: ["Not a valid email!", "Ist kleine gültige E-Mail!"],
  },
  contacts: {
    headline: ["Contacts", "Kontakt"],
    t1: ["Best Person", "Trauzeuge"],
    t2: ["Groom", "Bräutigam"],
    t3: ["Bride", "Braut"],
    t4: ["Best Person", "Trauzeugin"],
    t5: ["Best Person", "Trauzeugin"],
  },
  upload: {
    headline: ["Upload", "Upload"],
    teaser: ["Upload your best images!", "Ladet eure besten Bilder hoch!"],
  },
};

export type TCreateContext = (key: string) => (field: string) => string;
export type TLang = "en" | "de";
export type TSetLang = (lang: TLang) => void;

export default function useTranslations(): [TCreateContext, TLang, TSetLang] {
  const [lang, setLang] = useState<TLang>("de");
  const createContext = (key: string) => {
    const index = lang === "en" ? 0 : 1;
    const map = translations[key];

    if (typeof map === "undefined") throw Error("No such translation Context!");

    return (field: string) => {
      if (!map[field]) return `${key}_${field}:MISSING`;
      return map[field][index] || `${key}_${field}:MISSING_LANG`;
    };
  };
  return [createContext, lang, setLang];
}
