import React, { useState } from "react";
import { Grid } from "@nodeme/grid-react";
import { buildClasses, IDefinitions, ITheme } from "@nodeme/jss-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAddressCard,
  faBars,
  faCamera,
  faClock,
  faFileEdit,
  faGift,
  faShuttleVan,
  faTimes,
  faTurkey,
  faVirus,
} from "@fortawesome/pro-light-svg-icons";
import { Form, Select } from "@nodeme/forms-react";
import { TCreateContext, TLang, TSetLang } from "../Translations";

const styles = (theme: ITheme) => (definitions: IDefinitions) => ({
  placeholder: {
    height: "65px",
  },
  wrapper: {
    position: "fixed",
    borderBottom: "1px solid " + theme.palette.get("lightGrey"),
    left: 0,
    right: 0,
    top: 0,
    backgroundColor: "#fff",
    width: "100%",
    zIndex: 10,
  },
  logo: {
    textAlign: "left",
    margin: "0px 0px",
    paddingLeft: "4px",
    fontSize: "1.4rem",
    lineHeight: "1.4rem",
    "@media (max-width: 400px)": {
      fontSize: "1.2rem",
      lineHeight: "1.2rem",
    },
  },
  link: {
    textDecoration: "none",
    color: "#000",
    textAlign: "center",
    display: "block",
    [theme.breakpoints.down("lg")]: {
      textAlign: "center",
      margin: "24px 0px",
    },
  },
  icon: {
    marginRight: "8px",
    [theme.breakpoints.down("lg")]: {
      fontSize: "1.5rem",
    },
  },
  text: {
    display: "inline",
    [theme.breakpoints.down("lg")]: {
      display: "block",
      marginTop: "8px",
    },
  },
  respmenuBtn: {
    fontSize: "2rem",
  },
  respmenu: {
    display: "none",
    [theme.breakpoints.down("lg")]: {
      display: "block",
      width: "100%",
      position: "fixed",
      backgroundColor: "#fff",
      borderBottom: "1px solid " + theme.palette.get("lightGrey"),
      zIndex: 10,
    },
  },
});

interface IProps {
  createContext: TCreateContext;
  lang: TLang;
  setLang: TSetLang;
}

function Menu(props: IProps) {
  const classes = buildClasses(styles);
  const [open, setOpen] = useState(false);

  const getTR = props.createContext("menu");

  return (
    <div>
      <div className={classes.wrapper}>
        <Grid
          spacing={16}
          root={{ top: 16, bottom: 16 }}
          vertical
          alignItems="center"
        >
          <Grid item xs="rest-fixed" lg="auto">
            <h1 className={classes.logo}>Johannes & Mona</h1>
          </Grid>
          <Grid item xs="hidden" sm="hidden" md="hidden" lg="rest-fixed">
            <Grid
              spacing={16}
              root={{ top: 0, bottom: 0 }}
              vertical
              alignItems="center"
            >
              <Grid item lg="auto">
                <a className={classes.link} href="#schedule">
                  <FontAwesomeIcon className={classes.icon} icon={faClock} />
                  <div className={classes.text}>{getTR("schedule")}</div>
                </a>
              </Grid>
              <Grid item lg="auto">
                <a className={classes.link} href="#transpoort">
                  <FontAwesomeIcon
                    className={classes.icon}
                    icon={faShuttleVan}
                  />
                  <div className={classes.text}>{getTR("transport")}</div>
                </a>
              </Grid>
              <Grid item lg="auto">
                <a className={classes.link} href="#gifts">
                  <FontAwesomeIcon className={classes.icon} icon={faGift} />
                  <div className={classes.text}>{getTR("gifts")}</div>
                </a>
              </Grid>
              <Grid item lg="auto">
                <a className={classes.link} href="#covid">
                  <FontAwesomeIcon className={classes.icon} icon={faVirus} />
                  <div className={classes.text}>{getTR("covid")}</div>
                </a>
              </Grid>
              <Grid item lg="auto">
                <a className={classes.link} href="#meals">
                  <FontAwesomeIcon className={classes.icon} icon={faTurkey} />
                  <div className={classes.text}>{getTR("meals")}</div>
                </a>
              </Grid>
              <Grid item lg="auto">
                <a className={classes.link} href="#upload">
                  <FontAwesomeIcon className={classes.icon} icon={faCamera} />
                  <div className={classes.text}>{getTR("upload")}</div>
                </a>
              </Grid>
              <Grid item lg="auto">
                <a className={classes.link} href="#contacts">
                  <FontAwesomeIcon
                    className={classes.icon}
                    icon={faAddressCard}
                  />
                  <div className={classes.text}>{getTR("contact")}</div>
                </a>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs="60px">
            <Form onSubmit={(e) => e.preventDefault()}>
              <Select
                xs="60px"
                inline
                label="lang"
                value={props.lang}
                onChange={function (value?: string | number): void {
                  if (value === "de") props.setLang("de");
                  else props.setLang("en");
                }}
                options={[
                  { label: "DE", value: "de" },
                  { label: "EN", value: "en" },
                ]}
              />
            </Form>
          </Grid>
          <Grid item xs="40px" lg="hidden" xl="hidden">
            <FontAwesomeIcon
              className={classes.respmenuBtn}
              icon={open ? faTimes : faBars}
              onClick={() => {
                setOpen(!open);
              }}
            />
          </Grid>
        </Grid>
      </div>
      <div className={classes.placeholder} />
      {open && (
        <div className={classes.respmenu}>
          <Grid
            spacing={16}
            root={{ top: 16, bottom: 16 }}
            vertical
            alignItems="center"
            justify="center"
          >
            <Grid item xs={5}>
              <a
                className={classes.link}
                href="#schedule"
                onClick={() => setOpen(false)}
              >
                <FontAwesomeIcon className={classes.icon} icon={faClock} />
                <div className={classes.text}>{getTR("schedule")}</div>
              </a>
            </Grid>
            <Grid item xs={5}>
              <a
                className={classes.link}
                href="#transpoort"
                onClick={() => setOpen(false)}
              >
                <FontAwesomeIcon className={classes.icon} icon={faShuttleVan} />

                <div className={classes.text}>{getTR("transport")}</div>
              </a>
            </Grid>
            <Grid item xs={5}>
              <a
                className={classes.link}
                href="#gifts"
                onClick={() => setOpen(false)}
              >
                <FontAwesomeIcon className={classes.icon} icon={faGift} />
                <div className={classes.text}>{getTR("gifts")}</div>
              </a>
            </Grid>
            <Grid item xs={5}>
              <a
                className={classes.link}
                href="#covid"
                onClick={() => setOpen(false)}
              >
                <FontAwesomeIcon className={classes.icon} icon={faVirus} />
                <div className={classes.text}>{getTR("covid")}</div>
              </a>
            </Grid>
            <Grid item xs={5}>
              <a
                className={classes.link}
                href="#meals"
                onClick={() => setOpen(false)}
              >
                <FontAwesomeIcon className={classes.icon} icon={faTurkey} />
                <div className={classes.text}>{getTR("meals")}</div>
              </a>
            </Grid>
            <Grid item xs={5}>
              <a
                className={classes.link}
                href="#upload"
                onClick={() => setOpen(false)}
              >
                <FontAwesomeIcon className={classes.icon} icon={faCamera} />
                <div className={classes.text}>{getTR("upload")}</div>
              </a>
            </Grid>
            <Grid item xs={5}>
              <a
                className={classes.link}
                href="#contacts"
                onClick={() => setOpen(false)}
              >
                <FontAwesomeIcon
                  className={classes.icon}
                  icon={faAddressCard}
                />
                <div className={classes.text}>{getTR("contact")}</div>
              </a>
            </Grid>
          </Grid>
        </div>
      )}
    </div>
  );
}

export default Menu;
