import React from "react";
import { Container, Grid } from "@nodeme/grid-react";
import { buildClasses, IDefinitions, ITheme } from "@nodeme/jss-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAddressCard } from "@fortawesome/pro-light-svg-icons";
import { TCreateContext } from "../Translations";

const styles = (theme: ITheme) => (definitions: IDefinitions) => ({
  img: {
    width: "160px",
    height: "160px",
    borderRadius: "100%",
    display: "block-inline",
  },
  icon: { marginLeft: "8px" },
  text: {
    padding: "120px 0px 0px 100px",
  },
  contact: {
    textAlign: "center",
  },
  title: {
    margin: "8px 0px",
  },
  name: {
    margin: "4px 0px",
    fontWeight: 600,
  },
  phone: {
    margin: "4px 0px",
  },
  link: {
    color: theme.palette.get("success"),
  },
});

interface IProps {
  createContext: TCreateContext;
}

function Contacts(props: IProps) {
  const classes = buildClasses(styles);
  const getTR = props.createContext("contacts");
  return (
    <Container notFluid>
      <Grid
        spacing={32}
        vertical
        root={{ top: 0, bottom: 0, left: 0, right: 0 }}
        justify="center"
      >
        <Grid item>
          <h1 className={classes.headline}>
            {getTR("headline")}
            <FontAwesomeIcon className={classes.icon} icon={faAddressCard} />
          </h1>
        </Grid>
        <Grid md={5} lg={10 / 4}>
          <div className={classes.contact}>
            <div className={classes.title}>{getTR("t1")}</div>
            <div className={classes.name}>Bernhard Gönner</div>
            <div className={classes.phone}>
              <a className={classes.link} href="tel:+436801172028">
                +43 680 117 20 28
              </a>
            </div>
          </div>
        </Grid>
        <Grid md={5} lg={10 / 4}>
          <div className={classes.contact}>
            <div className={classes.title}>{getTR("t2")}</div>
            <div className={classes.name}>Johannes Geist</div>
            <div className={classes.phone}>
              <a className={classes.link} href="mailTo:johannesgeist@gmail.com">
                johannesgeist@gmail.com
              </a>
            </div>
            <div className={classes.phone}>
              <a className={classes.link} href="tel:+436642112532">
                +43 664 211 25 32
              </a>
            </div>
          </div>
        </Grid>
        <Grid md={5} lg={10 / 4}>
          <div className={classes.contact}>
            <div className={classes.title}>{getTR("t3")}</div>
            <div className={classes.name}>Mona Linder</div>
            <div className={classes.phone}>
              <a className={classes.link} href="mailTo:monalinder@gmx.at">
                monalinder@gmx.at
              </a>
            </div>
            <div className={classes.phone}>
              <a className={classes.link} href="tel:+436801172028">
                +43 676 61 73 273
              </a>
            </div>
          </div>
        </Grid>
        <Grid md={5} lg={10 / 4}>
          <div className={classes.contact}>
            <div className={classes.title}>{getTR("t4")}</div>
            <div className={classes.name}>Ina Linder</div>
            <div className={classes.phone}>
              <a className={classes.link} href="tel:+436769432407">
                +43 676 943 24 07
              </a>
            </div>
          </div>
        </Grid>
        <Grid md={5} lg={10 / 4}>
          <div className={classes.contact}>
            <div className={classes.title}>{getTR("t1")}</div>
            <div className={classes.name}>Alejandro Laguna-Duchesne</div>
            <div className={classes.phone}></div>
          </div>
        </Grid>
        <Grid md={5} lg={10 / 4}>
          <div className={classes.contact}>
            <div className={classes.title}>{getTR("t5")}</div>
            <div className={classes.name}>Camilla Schmid</div>
            <div className={classes.phone}>
              <a className={classes.link} href="tel:+436644070110">
                +43 664 4070110
              </a>
            </div>
          </div>
        </Grid>
      </Grid>
    </Container>
  );
}

export default Contacts;
